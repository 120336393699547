import axios, { AxiosError } from 'axios';
import { getSession } from 'next-auth/react';
import { signOut } from '~/utils/signOut';
import { API_BASE_URL } from './apiBaseUrl';
import { getCurrentLocaleReqHeader } from './i18nGlobal';

const fitxAxiosServices = axios.create({
  baseURL: API_BASE_URL
});

fitxAxiosServices.interceptors.request.use(
  async (config) => {
    const session = await getSession();

    if (session?.token.accessToken) {
      const token = new URLSearchParams(session?.token.accessToken).get('fitx');
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (typeof window !== 'undefined') {
      config.headers['Accept-Language'] = getCurrentLocaleReqHeader();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

fitxAxiosServices.interceptors.response.use(
  (response) => {
    if (response.data.isSuccess === false) {
      return Promise.reject(new AxiosError('Request failed due to isSuccess is false', '200', response.config, response.request, response));
    }

    return Promise.resolve(response);
  },
  async (error) => {
    if (error.response?.status === 401 && !window.location.href.includes('/login')) {
      await signOut();
      window.location.pathname = '/login';
    }
    return Promise.reject(error);
  }
);

export default fitxAxiosServices;
